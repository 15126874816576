
html {
    --adm-color-primary: #d9a004;
    --antd-wave-shadow-color: #d9a004;
}
:root {
    --adm-color-primary: #d9a004;
    --antd-wave-shadow-color: #d9a004;
}
.ant-modal-footer {
    padding: 10px 16px;
    text-align: center;
    background: transparent;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;
}

.ant-modal-confirm-body-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ant-modal-confirm .ant-modal-confirm-btns {
    float: inherit;
}

.ant-modal-confirm-body>.anticon {
    display: none;
}

.ant-btn {
    background-color: #d9a004;
    border-color: #d9a004;
    font-family: 'Pretendard', serif;
    font-weight: 500;
}

.ant-btn-primary {
    background-color: #d9a004;
    border-color: #d9a004 !important;
    font-family: 'Pretendard', serif;
    font-weight: 500;
}
.ant-btn-primary ::before {
    color: #389fff;
    background-color: #d9a004;
}

.ant-btn-primary :hover {
    color: #389fff;
    background-color: #d9a004;
}

.ant-btn-primary :active {
    color: #389fff;
    background-color: #d9a004;
}
.ant-btn-primary :focus {
    color: #389fff;
    background-color: #d9a004;
}

::selection {
    color: #389fff;
    background-color: #d9a004;
}

.ant-modal-confirm-body .ant-modal-confirm-content{
    font-size: 17px;
    font-weight: bold;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin: 0;
    text-align: center;
}

.ant-btn-primary > span{
    color: #389fff;
}

.ant-btn:hover {
    background-color: #d9a004;
    border-color: #d9a004;
    font-family: 'Pretendard', serif;
    font-weight: 500;
}

.ant-btn .ant-btn-default {
    background-color: #d9a004;
    border-color: #d9a004;
    font-family: 'Pretendard', serif;
    font-weight: 500;
}

.ant-btn-default > span {
    color: #ed1a2f;
}
