.b-blur40 {
    background: rgba(255, 255, 255, 0.40);
    backdrop-filter: blur(8.0px);
    -webkit-backdrop-filter: blur(8.0px);
}

.b-blur50 {
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(10.0px);
    -webkit-backdrop-filter: blur(10.0px);
}

.b-blur50-bk {
    background: rgba(0, 0, 20, 0.20);
    backdrop-filter: blur(5.0px);
    -webkit-backdrop-filter: blur(5.0px);
}

.b-empty-row {
    display: flex;
    flex: 1;
    height: 1px;
}

.b-width-100 {
    width: 100%;
}

.b-width-25 {
    width: calc(25% - 13px);
    min-width: calc(25% - 13px);
    max-width: calc(25% - 13px);
}

.b-width-200p {
    width: 200px;
}

.b-width-33 {
    width: calc(33% - 13px);
    min-width: calc(33% - 13px);
    max-width: calc(33% - 13px);
}