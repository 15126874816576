@font-face {
    font-family: "Aharoni_Bold";
    src: url("./Aharoni_Bold.ttf");
}

@font-face {
    font-family: "AvenirNextLTPro";
    font-weight: 600;
    src: url("./AvenirNextLTPro-Bold.otf");
}

@font-face {
    font-family: "AvenirNextLTPro";
    font-weight: 500;
    src: url("./AvenirNextLTPro-Demi.otf");
}

@font-face {
    font-family: "AvenirNextLTPro";
    font-weight: 400;
    src: url("./AvenirNextLTPro-Regular.otf");
}

@font-face {
    font-family: "NanumGothic";
    font-weight: 400;
    src: url("./NanumGothic.ttf");
}

@font-face {
    font-family: "NanumGothic";
    font-weight: 500;
    src: url("./NanumGothicBold.ttf");
}

@font-face {
    font-family: "NanumGothic";
    font-weight: 600;
    src: url("./NanumGothicExtraBold.ttf");
}

@font-face {
    font-family: "NanumSquare";
    font-weight: 300;
    src: url("./NanumSquareL.ttf");
}

@font-face {
    font-family: "NanumSquare";
    font-weight: 400;
    src: url("./NanumSquareR.ttf");
}

@font-face {
    font-family: "NanumSquare";
    font-weight: 500;
    src: url("./NanumSquareB.ttf");
}

@font-face {
    font-family: "NanumSquare";
    font-weight: 700;
    src: url("./NanumSquareEB.ttf");
}

@font-face {
    font-family: "Pretendard";
    font-weight: 300;
    src: url("./Pretendard-Light.woff");
}

@font-face {
    font-family: "Pretendard";
    font-weight: 400;
    src: url("./Pretendard-Regular.woff");
}

@font-face {
    font-family: "Pretendard";
    font-weight: 500;
    src: url("./Pretendard-Medium.woff");
}

@font-face {
    font-family: "Pretendard";
    font-weight: 600;
    src: url("./Pretendard-SemiBold.woff");
}

@font-face {
    font-family: "Pretendard";
    font-weight: 700;
    src: url("./Pretendard-Bold.woff");
}

@font-face {
    font-family: 'Poppins';
    font-weight: 900;
    src: url("./Poppins-Black.ttf");
}

@font-face {
    font-family: 'Poppins-Italic';
    font-weight: 900;
    src: url("./Poppins-Black.ttf");
}

@font-face {
    font-family: 'Poppins';
    font-weight: 800;
    src: url("./Poppins-ExtraBold.ttf");
}

@font-face {
    font-family: 'Poppins-Italic';
    font-weight: 800;
    src: url("./Poppins-ExtraBoldItalic.ttf");
}

@font-face {
    font-family: 'Poppins';
    font-weight: 700;
    src: url("./Poppins-Bold.ttf");
}

@font-face {
    font-family: 'Poppins-Italic';
    font-weight: 700;
    src: url("./Poppins-BlackItalic.ttf");
}

@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: url("./Poppins-SemiBold.ttf")
}

@font-face {
    font-family: 'Poppins-Italic';
    font-weight: 600;
    src: url("./Poppins-SemiBoldItalic.ttf");
}

@font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: url("./Poppins-Medium.ttf")
}

@font-face {
    font-family: 'Poppins-Italic';
    font-weight: 500;
    src: url("./Poppins-MediumItalic.ttf");
}

@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: url("./Poppins-Regular.ttf")
}

@font-face {
    font-family: 'Poppins-Italic';
    font-weight: 400;
    src: url("./Poppins-Italic.ttf");
}

@font-face {
    font-family: 'Poppins';
    font-weight: 300;
    src: url("./Poppins-Light.ttf")
}

@font-face {
    font-family: 'Poppins-Italic';
    font-weight: 300;
    src: url("./Poppins-LightItalic.ttf");
}

@font-face {
    font-family: 'Suit';
    font-weight: 800;
    src: url("./SUIT-ExtraBold.ttf")
}

@font-face {
    font-family: 'Suit';
    font-weight: 700;
    src: url("./SUIT-Bold.ttf")
}

@font-face {
    font-family: 'Suit';
    font-weight: 600;
    src: url("./SUIT-SemiBold.ttf")
}

@font-face {
    font-family: 'Suit';
    font-weight: 500;
    src: url("./SUIT-Medium.ttf")
}

@font-face {
    font-family: 'Suit';
    font-weight: 400;
    src: url("./SUIT-Regular.ttf")
}

@font-face {
    font-family: 'Suit';
    font-weight: 300;
    src: url("./SUIT-ExtraLight.ttf")
}

@font-face {
    font-family: 'Suit';
    font-weight: 200;
    src: url("./SUIT-Light.ttf")
}
