.mBtnWrap {
  display: flex;
  justify-content: center;
  width: 100%;
}

.mBtnWrap a,
.mBtnWrap .loginBtn {
  width: 35%;
  margin-right: 10px;
}

.mBtnWrap a:last-child {
  margin: 0;
}

.mBtnWrap button {
  width: calc(40% - 10px);
  height: 45px;
  background: none;
  color: #09348a;
  border: 1px solid #09348a;
  border-radius: 15px;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 44px;
  cursor: pointer;
}

.mBtnWrap button:active {
  color: #eeeeee;
  border: none;
  background-color: #09348a;
}

.mBtnWrap button:hover {
  background-color: #09348a;
  color: #ffffff;
  font-weight: 400;
}

.mBtnWrap button:last-child {
  margin-left: 10px;
}

.myAccountContainer .userInfoContainer .buttonContainer {
  grid-column: 1 / span 2;
  width: 100%;
  height: 50px;
  background-color: #cccccc;
  display: flex;
  justify-content: space-evenly;
}

.myAccountContainer .buttonContainer button {
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.myAccountContainer .buttonContainer button img {
  height: 15px;
  object-fit: cover;
  filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(20%)
    hue-rotate(39deg) brightness(93%) contrast(107%);
}

.myAccountContainer .buttonContainer button:not(:first-child) img {
  height: 30px;
}

.buttonContainer {
  width: 100%;
  max-width: 768px;
  height: 50px;
  display: grid;
  position: fixed;
  bottom: 0;
}

.buttonWrap {
  grid-column: 1 / span 2;
  width: 100%;
  height: 50px;
  background-color: #ededed;
  display: flex;
  justify-content: space-evenly;
}

.buttonWrap button {
  width: calc(100% / 3);
  background: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #000;
}

.buttonWrap button img {
  height: 18px;
  object-fit: cover;
  filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(20%)
    hue-rotate(39deg) brightness(93%) contrast(107%);
}

.buttonWrap button:nth-child(1) img {
  height: 14px;
}
