/*---------------- common ----------------*/

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

input[type="text"],
textarea,
select {
  font-size: 16px;
}

body {
  max-width: 768px;
  margin: 0 auto;
  align-content: center;
  align-items: center;
  text-align: center;
  background-color: #cbcbcb;
}

.headerContainer {
  width: 80%;
  margin: 0 auto 10px auto;
  text-align: left;
}

.payHeaderContainer {
  width: 50%;
  position: absolute;
  top: -40px;
  left: 0;
}

.headerContainer h2 {
  font-size: 1rem;
  font-weight: 400;
  color: #09348a;
  margin-bottom: 10px;
}

.headerContainer p {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #09348a;
  margin-top: 10px;
}

.submit {
  background-color: #ffffff;
  border: 5px solid #09348a;
  border-radius: 50%;
  cursor: pointer;
  width: 60px;
  height: 60px;
  line-height: 30px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.submit img {
  width: 40px;
  object-fit: cover;
  vertical-align: middle;
  filter: invert(8%) sepia(83%) saturate(7058%) hue-rotate(212deg)
    brightness(86%) contrast(102%);
}

.submit:active {
  background-color: #09348a;
}

.submit:active img {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(241deg)
    brightness(102%) contrast(105%);
}

@media (max-width: 768px) {
  .sideBox,
  .sideBox-open,
  .sideSbBox,
  .NavTopBar,
  .NavTopBar-sideOpen,
  .appContainer {
    display: none;
  }

  .appContainer-sideOpen {
    margin: 0;
  }
}

/*---------------- Mobile section ----------------*/

.mobileWrap {
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 11;
}

.mobileContents {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.backBtnWrap {
  margin-bottom: 30px;
}

.mobileLogo h1 img {
  width: 50%;
}

/*---------------- MobileButton ----------------*/

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.button-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}

.readyImg {
  filter: brightness(0) saturate(100%) invert(17%) sepia(92%) saturate(1864%)
    hue-rotate(205deg) brightness(87%) contrast(98%);
}

/*---------------- CustomButton ----------------*/

.customButton {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid #cccccc;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.customButton:last-child {
  margin: 0;
}

.customButton.active {
  background-color: #000000;
  border: 1px solid #000000;
}

/*---------------- JoinPage ----------------*/

.joinContainer {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  overflow: hidden;
}

.joinContainer .mobileLogo h1 img {
  width: 120px;
  margin-bottom: 15px;
}

.joinWrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.joinForm {
  text-align: center;
}

.joinContainer .inputContainer {
  height: 50px;
  position: relative;
  width: 80%;
  margin: 0 auto 20px auto;
  border: none;
}

.joinForm .input {
  background: none;
  border-radius: 15px;
  border: 2px solid #09348a;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  outline: 0;
  padding: 0 20px;
}

.joinForm .placeholder {
  color: #09348a;
  top: 9px;
  left: 20px;
  transform: translate(0, 50%);
  line-height: 1rem;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 0.2s, color 0.2s;
  font-size: 1rem;
  font-weight: 700;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  z-index: -1;
}

.input:focus ~ .placeholder {
  color: transparent;
}

.error {
  color: rgba(255, 0, 0, 0.4);
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 53px;
}

/*---------------- TermsAgree ----------------*/

.termsAgreeContainer {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.termsAgreeContainer .form {
  margin: 0;
}

.termsAgreeContainer .mobileLogo h1 img {
  width: 180px;
  margin-bottom: 15px;
}

.checkBoxContainer {
  position: relative;
  width: 80%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0 auto;
  font-size: 1rem;
  font-weight: 400;
  color: #09348a;
}

.checkBoxWrap {
  margin: 15px 0;
}

.allAgree {
  margin-bottom: 30px;
}

.allAgree:after {
  content: "";
  width: 100%;
  border-bottom: 1px solid #09348a;
  position: absolute;
  top: 65px;
  left: 0;
}

.checkBoxContainer input[type="checkbox"] + label > span {
  vertical-align: middle;
  padding-left: 10px;
  line-height: 1.8rem;
}

.checkBoxContainer input[type="checkbox"] {
  display: none;
}

.checkBoxContainer input[type="checkbox"] + label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #09348a;
  border-radius: 4px;
  vertical-align: middle;
}

.checkBoxContainer input[type="checkbox"]:checked + label:before {
  content: "";
  background-color: #09348a;
  border-color: #09348a;
  background-image: url("../img/check.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.messageContainer {
  width: 80%;
  margin: 0 auto 20px auto;
  text-align: left;
  font-size: 0.8rem;
  line-height: 1.4rem;
  color: #09348a;
}

.termsAgreeContainer .submitContainer .submit.disabled img {
  scale: 0.7;
}

.termsAgreeContainer .buttonContainer {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}

.buttonContainer .agreeBtn,
.buttonContainer .cancelBtn {
  width: 100px;
  height: 40px;
  border: 2px solid #09348a;
  border-radius: 17px;
  background: none;
  color: #09348a;
}

.buttonContainer .agreeBtn:hover,
.buttonContainer .cancelBtn:hover {
  background-color: #09348a;
}

.buttonContainer .agreeBtn:hover p,
.buttonContainer .cancelBtn:hover p {
  color: #ffffff;
}

.buttonContainer .agreeBtn p:visited,
.buttonContainer .cancelBtn p:visited,
.buttonContainer .agreeBtn a:visited,
.buttonContainer .cancelBtn a:visited {
  color: #09348a;
}

.buttonContainer .cancelBtn {
  margin-right: 10px;
}

.buttonContainer .agreeBtn.disabled,
.buttonContainer .agreeBtn.disabled:hover,
.buttonContainer .agreeBtn.disabled:hover p {
  background-color: #cbcbcb;
  border: 2px solid #cbcbcb;
  color: #999999;
  cursor: not-allowed;
}

/*---------------- RatePlan ----------------*/

.ratePlanContainer {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.ratePlanContainer .mobileLogo h1 img {
  width: 180px;
}

.ratePlanWrap {
  width: 85%;
  height: 80vh;
  margin: 0 auto;
  border: 2px solid #09348a;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.ratePlanWrap .planHeader {
  width: 90%;
  color: #09348a;
  margin: 0 auto;
}

.ratePlanWrap .planHeader h2 {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 15px;
}

.ratePlanWrap .planHeader p:first-child {
  margin-top: 20px;
}

.ratePlanWrap .planHeader p {
  font-size: 1rem;
  margin: 0 auto 15px auto;
}

.planBodyContainer {
  overflow-y: auto;
  height: 100%;
  max-height: 300px;
}

.planBodyText {
  position: relative;
  width: 90%;
  margin: 0 auto 15px auto;
  text-align: left;
  font-size: 0.9rem;
  color: #09348a;
}

.planBodyText span {
  margin-left: 50px;
}

.planBodyText img {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 15px;
  filter: invert(17%) sepia(52%) saturate(3360%) hue-rotate(213deg)
    brightness(87%) contrast(104%);
}

.planButtonContainer {
  width: 100%;
  height: 100%;
  max-height: 60px;
  margin-top: 10px;
}

.planButtonContainer .planButton {
  width: 40%;
  height: 40px;
  margin-right: 15px;
  border: none;
  border-radius: 30px;
  background-color: #09348a;
  color: #ffffff;
  font-size: 1.2rem;
  cursor: pointer;
}

.planButtonContainer .planButton.noMargin {
  margin: 0;
}

.planButtonContainer .planButton:hover {
  font-weight: 700;
}

/*---------------- SignUpComplete ----------------*/

.signUpContainer {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.signUpContainer .headerContainer {
  text-align: center;
}

.signUpContainer .mobileLogo h1 img {
  width: 250px;
  margin-bottom: 10px;
}

.signUpContainer .signUpTitle {
  font-size: 2.3rem;
  margin-bottom: 20px;
  color: #09348a;
}

.signUpContainer .signUpText {
  font-size: 1rem;
  color: #09348a;
  margin-bottom: 20px;
}

.signUpContainer .signUpText p {
  margin: 5px auto 10px auto;
}

.signUpContainer .signUpButtonContainer .okButton {
  width: 20%;
  height: 40px;
  border: none;
  border-radius: 30px;
  background-color: #09348a;
  color: #ffffff;
  font-size: 1.4rem;
  cursor: pointer;
}

.signUpContainer .signUpButtonContainer .okButton:hover {
  font-weight: 700;
}

/*---------------- MLogin ----------------*/

.mobileContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.mobileContainer .logoTitleBoxM {
  width: 100%;
  text-align: left;
}

.logoTitleBoxM .logoTitleLogo {
  width: 36px;
  height: 30px;
  position: absolute;
  transform: translate(-50%, 50%);
  top: 50%;
  bottom: 10px;
}

.logoTitleBoxM .logoTitleM {
  width: 100%;
  display: block;
  font-size: 3rem;
  color: #09348a;
  font-family: "Pretendard", serif;
  font-weight: 800;
}

.loginContainer .loginBoxM {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mobileContainer .loginIpBox {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  padding: 10px 0 10px 0;
  margin-top: 25px;
  border-radius: 10px;
}

.loginIpBlueBox {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  padding: 10px 0 10px 0;
  margin-top: 25px;
  border-radius: 10px;
  border: 1px solid #09348a;
}

.mobileContainer .loginIpWrap {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin: 10px auto 10px auto;
  /*margin: 0 auto;*/
}

.mobileContainer .loginIpWrap input {
  max-width: 240px;
  background-color: #ffffff;
  color: #09348a;
  font-size: 1.1rem;
}

.mobileContainer .loginIpTitle {
  width: 80px;
  padding-left: 15px;
  font-size: 0.9rem;
  color: #09348a;
  font-family: "Pretendard", serif;
  font-weight: 600;
}

/*---------------- MyAccount ----------------*/

.myAccountContainer {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
}

.myAccountContainer .myAccountHeader {
  width: 100%;
  height: 10%;
  background-color: #09348a;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-bottom: 1px solid #ffffff;
}

.myAccountContainer .myAccountHeader p {
  width: 90%;
  margin: 0 auto 10px auto;
  text-align: left;
}

.myAccountContainer .userInfoContainer {
  margin-bottom: 50px;
}

.myAccountContainer .userInfoWrap {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #cccccc;
}

.myAccountContainer .userInfoWrap .userInfoImg {
  width: 50px;
  height: 50px;
  background: none;
  border: 1px solid #2f2f2f;
  border-radius: 50%;
  margin-right: 20px;
  overflow: hidden;
  display: flex;
}

.myAccountContainer .userInfoWrap .userInfoImg img {
  justify-content: center;
  align-items: center;
}

.myAccountContainer .userInfoWrap:nth-child(1),
.myAccountContainer .userInfoWrap:nth-child(2),
.myAccountContainer .userInfoWrap:nth-child(4),
.myAccountContainer .userInfoWrap:nth-child(6),
.myAccountContainer .userInfoWrap:nth-child(8) {
  padding-left: 20px;
}

.myAccountContainer .userInfoWrap:nth-child(3),
.myAccountContainer .userInfoWrap:nth-child(5),
.myAccountContainer .userInfoWrap:nth-child(7),
.myAccountContainer .userInfoWrap:nth-child(10) {
  padding-right: 10px;
}

.myAccountContainer .userInfoWrap:nth-child(2),
.myAccountContainer .userInfoWrap:nth-child(4),
.myAccountContainer .userInfoWrap:nth-child(6),
.myAccountContainer .userInfoWrap:nth-child(8) {
  min-width: 220px;
}

.myAccountContainer .userInfoWrap:nth-child(8) {
  border: none;
}

.myAccountContainer .userInfoWrap:nth-child(2) .userInfoData,
.myAccountContainer .userInfoWrap:nth-child(3) .userInfoData {
  font-size: 1.8rem;
}

.myAccountContainer .userInfoContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: calc(74% / 4);
  width: 100%;
  height: 100%;
}

.myAccountContainer .userInfoContainer div:nth-child(1) {
  grid-column: 1 / span 2;
  background-color: #09348a;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.myAccountContainer .userInfoContainer div:nth-child(1) .userInfoData {
  color: #ffffff;
}

.userInfoContainer .userInfoWrap .userInfoData.name {
  font-size: 2.2rem;
}

.myAccountContainer .userInfoWrap .userInfoHeader {
  color: #999999;
  font-weight: 700;
  margin-bottom: 10px;
}

.myAccountContainer .userInfoWrap .userInfoData {
  font-size: 1.1rem;
  font-weight: 700;
  color: #09348a;
}

.myAccountContainer .accountB {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.myAccountContainer .accountB .userInfoWrap:nth-child(1) {
  grid-column: 1 / span 3;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  align-items: start;
}

.accountB .userInfoWrap:nth-child(2),
.accountB .userInfoWrap:nth-child(4),
.accountB .userInfoWrap:nth-child(6),
.accountB .userInfoWrap:nth-child(8) {
  min-width: auto;
}

.accountB .userInfoWrap:nth-child(2) {
  grid-area: 2 / 1 / 3 / 3;
}

.accountB .userInfoWrap:nth-child(3) {
  grid-area: 2 / 3 / 3 / 4;
}

.accountB .userInfoWrap:nth-child(4) {
  grid-area: 3 / 1 / 4 / 3;
}

.accountB .userInfoWrap:nth-child(5) {
  grid-area: 3 / 3 / 4 / 4;
}

.accountB .userInfoWrap:nth-child(6) {
  grid-area: 4 / 1 / 5 / 3;
}

.accountB .userInfoWrap:nth-child(7) {
  grid-area: 4 / 3 / 5 / 4;
}

.accountB .userInfoWrap:nth-child(8) {
  border: none;
  grid-area: 5 / 1 / 6 / 2;
}

.accountB .userInfoSession {
  margin-top: 20px;
}

.accountB .userInfoWrap:nth-child(9) {
  margin-top: 0;
  margin-left: 10px;
  border: none;
  grid-area: 5 / 2 / 6 / 3;
  text-align: left;
}

.accountB .userInfoWrap:nth-child(10) {
  margin-top: 0;
  border: none;
  grid-area: 5 / 3 / 6 / 4;
}

/*---------------- DayProgramM ----------------*/

.mobileContentsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.mobileContentsRow {
  width: 100%;
  margin: 10px auto;
  padding: 0 5px 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mobileContentsRow:nth-child(1) {
  margin-top: 40px;
}

.mobileContentsRow .calendarDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}

.mobileContentsRow .dayProgramCalBox {
  width: calc((100% / 8));
  height: 50px;
  border: 1px solid #09348a;
  border-radius: 8px;
  color: #09348a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sbdayprogram-dayTiTleTextM {
  font-size: 1.4rem;
  font-weight: 500;
  color: #000;
}

.mobileContentsRow .dayProgramCalBoxOn {
  color: #fff;
  background-color: #09348a;
  border-color: #09348a;
  font-weight: bold;
}

/* MIIT에도 추가예정 */
.calBoxWeek,
.calBoxDay {
  font-size: 0.5rem;
}

.calBoxWeek {
  font-size: 0.4rem;
  margin-bottom: 5px;
}

.calBoxDay {
  font-weight: 500;
  font-size: 1rem;
}

.tutorialLogoTitle {
  margin-top: 0;
  margin-bottom: 15px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.tutorialLogoTitle img {
  width: 44px;
  height: 44px;
}

.contentsBox .calendarWrap {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.appContainer-sideOpen {
  width: 100%;
}

.mobileContentsContainer .hamburgerWrap {
  position: absolute;
  top: 20px;
  left: 20px;
}

.mobileContentsContainer .containerWrap {
  max-width: 768px;
  width: 100%;
  height: 70%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.programItembox {
  height: 120px;
  margin: auto 10px 20px 10px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid #09348a;
  display: flex;
  flex: 0 0 calc(50% - 20px);
  flex-wrap: wrap;
  flex-direction: column;
  cursor: pointer;
}

.programItembox .programItemboxTitle {
  margin: auto;
  font-size: 1rem;
  font-weight: 500;
  color: #09348a;
}

.programItembox .programItemBtn {
  min-width: 150px;
  width: 100%;
  height: 40px;
  background-color: #09348a;
  z-index: 99;
}

/*.programItembox .itembox-bottomBtnWrap {*/
/*    display: flex;*/
/*    width: 100%;*/
/*    flex-direction: row;*/
/*    justify-content: space-around;*/
/*    align-items: flex-start;*/
/*    margin-top: 10px;*/
/*    margin-bottom: 6px;*/
/*}*/

/*.programItembox .optioncb-icon {*/
/*    width: 22px;*/
/*    height: 22px;*/
/*    margin-left: 0;*/
/*    cursor: pointer;*/
/*    filter: brightness(0) saturate(100%) invert(100%) sepia(25%) saturate(2%) hue-rotate(151deg) brightness(106%) contrast(100%);*/
/*}*/

/*.programItembox .optioncb-icon-on {*/
/*    width: 22px;*/
/*    height: 22px;*/
/*    margin-left: 0;*/
/*    cursor: pointer;*/
/*}*/

/*@media (max-width: 768px) {*/
/*    .react-calendar__navigation {*/
/*        background-color: #09348a;*/
/*    }*/

/*    .react-calendar__navigation button:disabled {*/
/*        background-color: #09348a;*/
/*    }*/

/*    .react-calendar__tile--active {*/
/*        background: #09348a;*/
/*    }*/

/*    .react-calendar__tile--active:enabled:hover,*/
/*    .react-calendar__tile--active:enabled:focus {*/
/*        background: #09348a;*/
/*    }*/
/*}*/

/*---------------- Tutorial ----------------*/

.tutorialContainer {
  overflow-y: auto;
}

.titleContainer {
  width: 100%;
  height: 80px;
  line-height: 100px;
  margin: 0 auto;
  padding-left: 20px;
  font-size: 2.2rem;
  font-weight: 700;
  border-bottom: 2px solid #09348a;
  color: #09348a;
  text-align: left;
}

.tutorialLogoImg {
  height: 20px;
}

.container-zero .tutorialLogoTitle {
  display: none;
}

.tutorialLogoTxt {
  width: 100%;
  height: 60px;
  line-height: 65px;
  margin: auto;
  padding-left: 60px;
  font-size: 2.2rem;
  border-bottom: 2px solid #09348a;
  color: #09348a;
  text-align: left;
}

.tutorialWrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  margin: 30px auto 0 auto;
  padding-bottom: 50px;
}

.tutorialImg {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 5px;
}

.tutorialItem {
  overflow: hidden;
  margin: 0 6px 20px 6px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(55, 55, 55, 0.22);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  flex: 1 calc(100% / 3);
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.tutorialItem:last-child {
  max-width: calc((100% / 2) - 10px);
}

.tutorialName {
  width: 100%;
  height: 30px;
  position: absolute;
  background-color: #999999;
  bottom: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.tutorialName span {
  font-size: 0.85em;
  color: #ffffff;
  flex: 1 1 0;
}

.videoContainer {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
}

.videoPlayContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.videoPlayWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  flex: 1 1;
  height: 100%;
}

/*---------------- ProgramsItem ----------------*/
.programContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  height: 100vh;
  padding-top: 75px;
  max-width: 100%;
}

.programContainer .programWrap {
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.programContainer .packagesItemTabWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.programContainer .packagesItemTabBtnOn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border-style: solid;
  border-bottom-width: 5px;
  border-color: #09348a;
  color: #09348a;
  cursor: pointer;
}

.packagesItemTabBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 23px 20px;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  border-color: #09348a;
  color: #000000;
  cursor: pointer;
}

.programContainer .packagesItemTabTxt {
  font-family: "Pretendard", serif;
  font-weight: 500;
  font-size: 1.2em;
}

.programContainer .containerWrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin: 0 auto;
  padding-bottom: 50px;
}

.programContainer .programAddItem {
  overflow: hidden;
  margin: 0 10px 20px 10px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #09348a;
  display: flex;
  flex: 1 calc(100% / 3);
  height: 100px;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  color: #09348a;
  font-size: 30px;
}

.programContainer .programAddItem img {
  width: 60px;
  height: 60px;
  filter: brightness(0) saturate(100%) invert(20%) sepia(44%) saturate(2520%)
    hue-rotate(197deg) brightness(92%) contrast(104%);
}

.searchContainer {
  width: calc(100% - 20px);
  margin: 20px auto;
}

.searchContainer form {
  width: 100%;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
}

.inputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border: 2px solid #65737e;
  border-radius: 8px;
}

.inputContainer input {
  font-size: 1rem;
}

.searchContainer form .searchInput {
  width: 90%;
  background: none;
  border: none;
  font-size: 10pt;
  float: left;
  color: #09348a;
  padding-left: 20px;
}

.searchContainer form .searchInput#search::-webkit-input-placeholder {
  color: #09348a;
}

.searchContainer form .searchInput#search:-moz-placeholder {
  /* Firefox 18- */
  color: #65737e;
}

.searchContainer form .searchInput#search::-moz-placeholder {
  /* Firefox 19+ */
  color: #65737e;
}

.searchContainer form .searchInput#search:-ms-input-placeholder {
  color: #65737e;
}

.searchContainer form .searchInput:hover,
.searchContainer form .searchInput:focus,
.searchContainer form .searchInput#search:active {
  outline: none;
  background: #ffffff;
}

.searchSubmit {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: 10px;
  line-height: 0;
  padding-right: 10px;
}

.searchSubmit img {
  width: 30px;
  height: 30px;
  filter: brightness(0) saturate(100%) invert(46%) sepia(15%) saturate(375%)
    hue-rotate(164deg) brightness(93%) contrast(92%);
}

/*---------------- SetupFolder, SetupItem  ----------------*/

.setupItemBox {
  flex: 1 calc(100% / 3);
  height: 120px;
  margin: 0 10px 20px 10px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #09348a;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-start;
  position: relative;
}

.setupItemBox:last-child {
  max-width: calc((100% / 2) - 20px);
}

.setupItemTitle {
  font-size: 1rem;
  font-weight: bold;
  color: #09348a;
  background-color: #ffffff;
  margin: auto 0;
  width: 100%;
}

.setupItemBtnContainer {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding: 7px 0 1px 0;
  background-color: #09348a;
}

.setupItemBtnWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
}

.optionIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
    hue-rotate(93deg) brightness(103%) contrast(103%);
}

.optionIconOn {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.setupItemCalendar {
  position: absolute;
  top: 5px;
  right: 5px;
}

.setupItemCalendar button {
  background: none;
  border: none;
}

.setupItemCalendar img {
  width: 18px;
  filter: brightness(0) saturate(100%) invert(20%) sepia(44%) saturate(2520%)
    hue-rotate(197deg) brightness(92%) contrast(104%);
}

/*---------------- ProgramsVideo  ----------------*/

.programsVideoContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  padding-top: 50px;
}

.programsVideoContainer .containerWrap {
  width: 100%;
  padding: 0 15px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.programsVideoContainer .hamburgerIcon {
  position: absolute;
  top: 10px;
  right: 5px;
}

.programsVideoContainer .hamburgerWrap {
  position: absolute;
  top: 15px;
  right: 50px;
  background-color: #cccccc;
  border-radius: 15px;
  padding: 10px 15px;
  z-index: 999;
  text-align: left;
}

.programsVideoContainer .hamburgerWrap li {
  margin: 15px 0;
  font-size: 1rem;
  font-weight: 500;
}

.programsVideoContainer .hamburgerWrap li:hover {
  font-weight: 700;
}

/* hamburger button */
.hamburgerIcon {
  height: 40px;
  width: 40px;
  position: absolute;
  background: none;
  border: none;
  transition: transform 0.3s;
}

.hamburgerIcon.load::before,
.hamburgerIcon.load::after {
  animation: none;
}

.hamburgerIcon::after,
.hamburgerIcon::before {
  content: "";
  position: absolute;
  top: calc(50% - 2px);
  left: calc(50% - 2px);
  width: 5px;
  border-radius: 5px;
  height: 5px;
  display: block;
  background: #333;
  transform-origin: center;
}

.hamburgerIcon::before {
  transform: translateY(-12px);
  box-shadow: 0 12px 0 #333;
  animation: top 0.7s forwards;
}

.hamburgerIcon::after {
  animation: bottom 0.7s forwards;
  transform: translateY(12px);
}

.hamburgerIcon.is-active::before {
  animation: top-active 0.7s forwards;
}

.hamburgerIcon.is-active::after {
  animation: bottom-active 0.7s forwards;
}

@keyframes top {
  0% {
    left: 6px;
    box-shadow: none;
    width: 30px;
    transform: translateY(0) rotate(45deg);
  }
  50% {
    left: calc(50% - 2px);
    width: 5px;
    box-shadow: none;
    transform: translateY(0) rotate(0);
  }
  100% {
    left: calc(50% - 2px);
    width: 5px;
    box-shadow: 0 12px 0 #333;
    transform: translateY(-12px) rotate(0);
  }
}

@keyframes bottom {
  0% {
    left: 6px;
    width: 30px;
    transform: translateY(0) rotate(-45deg);
  }
  50% {
    left: calc(50% - 2px);
    width: 5px;
    transform: translateY(0) rotate(0);
  }
  100% {
    left: calc(50% - 2px);
    width: 5px;
    transform: translateY(12px) rotate(0);
  }
}

@keyframes top-active {
  50% {
    left: calc(50% - 2px);
    width: 5px;
    box-shadow: none;
    transform: translateY(0px) rotate(0);
  }
  100% {
    left: 6px;
    box-shadow: none;
    width: 30px;
    transform: translateY(0) rotate(45deg);
  }
}

@keyframes bottom-active {
  50% {
    left: calc(50% - 2px);
    width: 5px;
    box-shadow: none;
    transform: translateY(0px) rotate(0);
  }
  100% {
    left: 6px;
    box-shadow: none;
    width: 30px;
    transform: translateY(0) rotate(-45deg);
  }
}

/* hamburger button end */

.programsTitleWrap {
  width: 100%;
  padding: 0 18px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.programsTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  border-style: solid;
  border-bottom-width: 5px;
  border-color: #09348a;
  color: #09348a;
  cursor: pointer;
  font-weight: 500;
  font-size: 1.2em;
}

.videoTitleWrap {
  width: 100%;
  padding: 0 18px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  position: relative;
}

.videoTitleContents {
  font-size: 1.2em;
  color: #000000;
  font-family: "Pretendard", serif;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  border-right: 1px solid #000000;
}

.videoTitleContents:first-child {
  width: 100%;
  grid-area: 1 / 1 / 2 / 3;
  flex-direction: row;
  color: #09348a;
  text-align: left;
  border: none;
}

.videoTitleContents:last-child {
  border: none;
}

.videoTitleContents:first-child:after {
  content: "";
  width: 55%;
  border-bottom: 1px solid #09348a;
  position: absolute;
  top: 64px;
}

.videoTitleContents.noBorder {
  border: none;
}

.videoTitleContents.noBorder .videoTitle {
  margin-right: 15px;
  line-height: 1.2rem;
  padding-bottom: 7px;
}

.videoTitleContentsTime {
  border: 2px solid #09348a;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.myTimeTxtInput {
  background: none;
  border: none;
  margin-left: 10px;
  font-weight: 600;
}

.myExTimeInnerWrap {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.myTimeTxtAll {
  font-size: 1.2rem;
  color: #09348a;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.videoTitle {
  font-family: "Pretendard", serif;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.6rem;
}

.myTimeTxt {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: "AvenirNextLTPro", serif;
}

.fontRed {
  color: #af1010;
}

.fontGreen {
  color: #0a810a;
}

.packagesItemTabBtnOn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 4px;
  border-color: #09348a;
  color: #09348a;
  cursor: pointer;
}

.packagesItemTabTxt {
  font-family: "AvenirNextLTPro", serif;
  font-weight: 600;
  font-size: 1.2em;
}

.dndItemWrap {
  flex: 1 calc(100% / 4);
  height: 230px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.videoDndWrapIn {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.videoDndWrapRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px auto 0 auto;
  padding: 0 10px;
}

/*---------------- TimeSetup  ----------------*/
.timeSetupContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.TitleWrap {
  width: 100%;
  padding: 50px 18px 0 18px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.timeSetupContainer .hamburgerIcon {
  position: absolute;
  top: 10px;
  right: 5px;
}

.timeSetupContainer .hamburgerWrap {
  position: absolute;
  top: 15px;
  right: 50px;
  background-color: #cccccc;
  border-radius: 15px;
  padding: 10px 15px;
  z-index: 999;
  text-align: left;
}

.timeSetupContainer .hamburgerWrap li {
  margin: 15px 0;
  font-size: 1rem;
  font-weight: 500;
}

.timeSetupContainer .hamburgerWrap li:hover {
  font-weight: 700;
}

.timeSetupContainer .elipseButton {
  display: flex;
  position: absolute;
  top: 15px;
  right: 30px;
}

.timeSetupContainer .elipseButton p {
  width: 30px;
  height: 30px;
  border: 1px solid #000000;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.timeSetupContainer .elipseButton p:hover {
  border: none;
  background-color: #000000;
}

.timeSetupContainer .elipseButton p:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(16deg) brightness(105%) contrast(103%);
}

.timeSetupContainer .elipseButton p img {
  width: 25px;
  height: 25px;
}

.timeSetupContainer .elipseButton .elipseX img {
  width: 15px;
  height: 15px;
}

.tabContainer .tabButtonWrap {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto 20px auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.tabContainer .timeSetupTabBtn {
  display: flex;
  flex: 0 12%;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 23px 20px;
  border-style: solid;
  border-bottom-width: 1px;
  border-color: #09348a;
  color: #000000;
  cursor: pointer;
  position: relative;
}

.tabContainer .timeSetupTabBtn.active {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #09348a;
  cursor: pointer;
}

.tabContainer .timeSetupTabBtn.active:after {
  content: "";
  border-style: solid;
  border-bottom-width: 5px;
  border-color: #09348a;
  width: 100%;
  position: absolute;
  top: 51px;
}

.tabContainer .tabContentsWrap {
  width: 90%;
  margin: 0 auto;
}

.tabContainer .tabContents {
  position: relative;
}

.tabContainer .tabContentsBox {
  width: 100%;
  display: flex;
  border: 1px solid #09348a;
  border-radius: 15px;
  margin-bottom: 10px;
  overflow: hidden;
}

.tabContainer .tabContentsBox div {
  flex: 1;
  padding: 40px 0;
  font-weight: 500;
}

.tabContainer .tabContentsBox p {
  line-height: 1.2rem;
}

.tabContainer .tabContentsBox .tabHypoImg {
  width: 100%;
  background-color: #09348a;
}

.tabContainer .tabContentsBox .tabHypoExercise p {
  border-right: 1px;
  border-right-style: solid;
  border-color: #000000;
  color: #af1010;
}

.tabContainer .tabContentsBox .tabHypoRest p {
  color: #0a810a;
}

.timeSetupContainer .brakeTime {
  flex-direction: row;
  grid-area: 2 / 1 / 3 / 3;
  color: #09348a;
}

.timeSetupContainer .videoTitle {
  white-space: nowrap;
}

.timeSetupContainer .videoTitleContentsTime {
  padding: 22px 5px 18px 5px;
}

/*---------------- videoplay  ----------------*/

.videoPlayHeader {
  width: 85%;
  min-height: 85px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

.videoPlayHeader .videoPlayHeaderText {
  font-size: 1.2rem;
  color: #ffffff;
  text-align: center;
  font-weight: 400;
}

.videoPlayHeader .videoPlayHeaderExtext {
  font-size: 2.4rem;
  text-align: center;
  font-weight: 900;
  text-shadow: 2px 1px 1px black;
  margin-right: 10px;
}

.videoplayLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 54px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.videoLogo {
  width: 24px;
  height: 22px;
  margin: 5px auto 8px auto;
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(288deg) brightness(102%) contrast(102%);
}

.videoplayLeftBtnWrapPlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 40px;
  margin: 0 auto;
  padding: 10px 0;
  font-size: 1.2rem;
  cursor: pointer;
  font-weight: 600;
}

/*---------------- Account Setup  ----------------*/

.accountSetupContainer {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: hidden;
}

.setupSquareContainer {
  border: 1px solid #09348a;
  border-radius: 15px;
  width: 90%;
  margin: 0 auto 20px auto;
}

.setupSquareBox {
  margin-top: 85px;
  padding-bottom: 50px;
}

.setupSquareContainer .setupSquareWrap {
  height: 100px;
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  color: #09348a;
}

.setupSquareContainer .setupSquareWrap .setupSquareOffice {
  margin-left: 30px;
}

.setupSquareContainer .setupSquareWrap .setupSquareImg {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
}

.setupSquareContainer .setupSquareImgWrap,
.setupSquareImg.ml10 {
  margin-left: 10px;
}

.setupSquareContainer .setupSquareImgWrap .setupSquareImg {
  margin-bottom: 10px;
}

.setupSquareWrap .setupSquareButtons {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
}

/*---------------- Music Page  ----------------*/

.musicContainer {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: hidden;
}

.musicContainer .musicAddBox {
  width: 100%;
  height: 50px;
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  z-index: 99;
}

.musicContainer .musicAddBox .musicAddBoxWrap {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.musicContainer .musicAddBox .musicAddBoxWrap .musicAdd,
.musicFolderAdd {
  width: 25px;
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: 15px;
}

.musicContainer .musicAddBox .musicAddBoxWrap .musicFolderAdd {
  margin-left: 5px;
}

.musicContainer .musicAddBox .musicAddBoxWrap div img {
  width: 25px;
  height: 25px;
}

.musicContainer .musicAddBox .editBtn {
  border: none;
  background: none;
  margin-right: 15px;
  font-weight: 700;
  color: #bebebe;
}

/* Modal 스타일링 */

.informationBox {
  width: 300px;
  height: 400px;
  margin: 0 auto;
  padding: 0 15px;
  background-color: #eee;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  z-index: 999;
}

.modalAlertBox {
  width: 300px;
  height: 200px;
  margin: 0 auto;
  background-color: #eee;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  z-index: 999;
}

.modalAlertBox h2 {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 25px;
}

.modalAlertBox button {
  background-color: #09348a;
  margin: 0 auto;
  border: none;
  width: 75px;
  height: 30px;
  border-radius: 15px;
  color: #e0e0e0;
}
