.ant-modal-content{
    border-radius: 15px;
    overflow: hidden;
    background-color:#ebebeb !important;
}

.bpCalendar {
    width: 1350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    line-height: 1.125em;
}

.ant-modal-header {
    font-size: 0.9rem;
    color: #000000;
    font-family: 'Pretendard', serif;
    font-weight: 600;
    background-color:#ebebeb !important;
}
