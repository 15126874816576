body {
  touch-action: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

input[type="text"],
textarea,
select {
  font-size: 16px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 9999s ease-out;
  -webkit-box-shadow: 0 0 0px 1000px black inset !important;
  -webkit-text-fill-color: #fff !important;
}

.dot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  font-size: 0.4rem;
  line-height: 0.7rem;
  font-weight: bold;
  color: #ff8000;
  padding-top: 1px;
}

.calitem {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.NavTopBar {
  width: 100%;
  position: fixed;
  z-index: 90;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
}

.NavTopBar-sideOpen {
  width: calc(100% - 224px);
  transition: all 0.2s ease-in;
}

.NavTopBar-on-btnBox {
  width: 100%;
  position: fixed;
  z-index: 99;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
  display: flex;
  transition: all 0.1s ease-in;
}

.NavTopBar-on-btnBox-sideOpen {
  width: calc(100% - 224px);
  transition: all 0.2s ease-in;
}

.NavTopBar-logout {
  font-size: 0.8rem;
  cursor: pointer;
  /* border-style: solid;
    border-width: 1px;
    border-color: #cfcfcf;
    border-radius: 2px; */
  color: #787878;
  padding: 4px 8px;
  margin-right: 15px;
  font-weight: 500;
}

.appContainer {
  width: 100%;
  min-height: 100vh;
  position: fixed;
  margin-left: 0px;
  transition: all 0.1s ease-in;
}

.appContainer-sideOpen {
  width: calc(100% - 224px);
  margin-left: 224px;
  transition: all 0.2s ease-in;
}

.pay-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 15px;
  text-align: center;
}

.pay-price {
  font-size: 1.5rem;
  color: #000;
  font-weight: bold;
  margin-top: 15px;
  text-align: center;
}

.pay-tSub {
  font-size: 0.9rem;
  color: #000;
  text-align: center;
}

.pay-btn {
  width: 48%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eeeeee;
  border-style: solid;
  border-color: #ffffff;
  border-width: 1px;
  border-radius: 20px;
  cursor: pointer;
}

.pay-btnWrap {
  width: 84%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  position: absolute;
  bottom: 30px;
}

.pay-tSubL {
  font-size: 0.9rem;
  color: #000;
  width: 80%;
  text-align: center;
  line-height: 1.2rem;
}

.pay-tSubCheck {
  font-size: 0.9rem;
  color: #000;
  width: 80%;
  text-align: center;
}

.pay-btnTxt {
  font-size: 0.9rem;
  color: #000;
  text-align: center;
  margin-top: 2px;
}

.pay-wrap {
  min-width: 250px;
  min-height: 565px;
  height: 80vh;
  margin-bottom: 60px;
  margin-left: 6px;
  margin-right: 6px;
  background-color: #eeeeee;
  box-shadow: 0 8px 16px 0 rgba(55, 55, 55, 0.22);
  border-radius: 10px;
  border: 1px solid #000;
  color: #000;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: center;
  position: relative;
}

.contentsBox {
  width: 100%;
}

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  /* overflow-x: hidden; */
  overflow-x: auto;
  width: 100%;
  height: 100vh;
  padding-top: 65px;
  max-width: 100%;
}

.container-zero {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  /* overflow-x: hidden; */
  overflow-x: auto;
  width: 100%;
  height: 100vh;
}

.container-zero-row {
  display: flex;
  position: relative;
  flex-direction: row;
  flex: 1;
  /* overflow-x: hidden; */
  overflow-x: auto;
  width: 100%;
  height: 100vh;
}

.sbvideoplay-contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sbvideoplay-header {
  width: 100%;
  min-height: 85px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.sbvideoplay-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 54px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sbvideoplay-left-scroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100vh; */
  height: 100%;
  width: 54px;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* background-color: #ffbf00; */
}

.sbvideoplay-left-scroll::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera*/
}

.sbvideoplay-leftBtnWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 44px;
  min-height: 44px;
  border-radius: 25px;
  /* background-color: #fcc01c; */
  margin-top: 5px;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.4rem;
  cursor: pointer;
  padding-top: 3px;
}

.sbvideoplay-leftBtnWrap-play {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 6px;
  margin-left: 12px;
  min-width: 50px;
  min-height: 40px;
  border-radius: 25px 0 0 25px;
  background-color: #fcc01c;
  margin-top: 12px;
  color: #000000;
  font-size: 1.1rem;
  cursor: pointer;
  padding-top: 6px;
  font-family: "AvenirNextLTPro", serif;
  font-weight: 600;
}

.sbvideoplay-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  flex: 1;
  /* height: 100vh; */
  height: 100%;
}

.modal-reply-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.container-column {
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.container-row {
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.container-wrap {
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;
  /* width: calc(100% - 40px); */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.sbday-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94%;
  padding: 7px 0px;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #e3e3e3;
}

.sbday-text {
  font-size: 0.8rem;
  color: #2f2f2f;
  text-align: center;
  font-family: "Pretendard";
  font-weight: 600;
}

.sbvideoplay-header-extext {
  margin-top: 8px;
  font-size: 2.4rem;
  color: #ff0000;
  text-align: center;
  font-family: "Pretendard";
  font-weight: 900;
  text-shadow: 2px 1px 1px black;
}

.sbvideoplay-header-text {
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  font-family: "Pretendard", serif;
  font-weight: 700;
}

.container-title {
  font-family: "Pretendard";
  font-size: 1.2rem;
  color: #542000;
  font-weight: 500;
}

.yellowBox {
  min-height: 200px;
  box-shadow: 0 8px 16px 0 #f5c242;
  border-radius: 10px;
  border: 1px solid #f5c242;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-sub {
  font-size: 1.1rem;
  font-weight: normal;
  margin-left: 8px;
  color: #808080;
  margin-top: 7px;
}

.history-contents {
  font-size: 1rem;
  font-weight: normal;
  margin-left: 8px;
  color: #808080;
}

.comment-id {
  font-size: 0.9rem;
  font-weight: bold;
  color: #000000;
}

.comment-date {
  font-size: 0.8rem;
  font-weight: normal;
  color: #808080;
}

.comment-contents {
  font-size: 0.9rem;
  font-weight: normal;
  color: #000000;
}

.history-date {
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 8px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 10px;
}

.itembox01 {
  min-width: 250px;
  min-height: 100px;
  margin-bottom: 30px;
  margin-left: 6px;
  margin-right: 6px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(55, 55, 55, 0.22);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-start;
}

.itembox02 {
  min-width: 250px;
  min-height: 255px;
  margin-bottom: 30px;
  margin-left: 6px;
  margin-right: 6px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px 0 rgba(55, 55, 55, 0.22);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-start;
}

.itembox02Folder {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-start;
}

.noShadow {
  box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0);
}

.innerShadow01 {
  box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 1);
}

.itembox03-modal {
  width: 100%;
  height: 190px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.itembox03 {
  min-width: 250px;
  min-height: 255px;
  max-height: 255px;
  overflow: hidden;
  margin-bottom: 20px;
  margin-left: 6px;
  margin-right: 6px;
  background-color: #f2bb3d;
  box-shadow: 0 8px 16px 0 rgba(55, 55, 55, 0.22);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.itembox04 {
  min-width: 230px;
  min-height: 160px;
  max-height: 160px;
  overflow: hidden;
  margin-bottom: 20px;
  margin-left: 6px;
  margin-right: 6px;
  background-color: #f2bb3d;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.itemBoxModalVideo {
  max-width: 190px;
  min-width: 190px;
  min-height: 190px;
  max-height: 190px;
  overflow: hidden;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 8px;
  background-color: #f2bb3d;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
}

.itemBoxModal {
  max-width: 150px;
  min-width: 150px;
  min-height: 125px;
  max-height: 125px;
  overflow: hidden;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 8px;
  background-color: #f2bb3d;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
}

.itemBoxModal-bottomBtnWrap-btnText {
  font-size: 0.74rem;
  font-weight: bold;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: #cfcfcf;
  padding: 2px 6px;
  border-radius: 4px;
}

.itembox01-topNotiWrap {
  display: inline-flex;
  padding: 5px 8px 3px 8px;
  border-radius: 30px;
  margin-bottom: 12px;
  font-size: 0.6rem;
  font-weight: bold;
  margin-left: -2px;
  border-width: 1.2px;
  border-style: solid;
}

.itembox01-title {
  font-size: 1.1rem;
  font-weight: bold;
  color: #210e05;
  margin-bottom: 15px;
}

.itembox01-sub01 {
  font-size: 0.85rem;
  color: #4d4d4d;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 11px;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #ebebeb;
}

.itembox02-scrollview {
  width: 100%;
  min-height: 130px;
  max-height: 130px;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.itembox02folder-scrollview {
  width: 100%;
  max-height: 185px;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.itembox02-title02 {
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  width: 100%;
  padding: 16px 20px;
  margin-bottom: 15px;
}

.itembox02Folder-title02 {
  font-size: 0.9rem;
  font-weight: bold;
  color: #ffffff;
  width: 100%;
  padding: 10px 10px;
  margin-bottom: 15px;
}

.itembox02-title03 {
  font-size: 0.9rem;
  font-weight: bold;
  color: #ffffff;
  width: 100%;
  padding: 16px 5px;
  margin-bottom: 15px;
}

.itembox05-title02 {
  font-size: 1.1rem;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 1.3rem;
}

.itembox02-sub02-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  justify-items: flex-start;
  width: calc(100% - 20px);
  /* width:100%; */
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #ebebeb;
}

.itembox02Folder-sub02-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  justify-items: flex-start;
  width: calc(100% - 20px);
  /* width:100%; */
  margin-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #ebebeb;
}

.itemboxFolder {
  width: calc(32% - 7px);
  padding: 8px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 8px;
  background-color: #ebebeb;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  flex-wrap: wrap;
}

.itembox01-sub02 {
  font-size: 0.85rem;
  color: #383838;
}

.itembox01Folder-sub02 {
  font-size: 0.75rem;
  color: #383838;
}

.itembox01-sub04 {
  font-size: 0.85rem;
  color: #383838;
  font-weight: bold;
  width: 100%;
  text-align: center;
  display: block;
  word-break: break-all;
}

.itembox01-sub03 {
  font-size: 0.85rem;
  color: #4d4d4d;
  margin-left: 4px;
  cursor: pointer;
}

.itembox01-sub03:hover {
  color: #0000ff;
}

.itembox01-bottomBtnWrap {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
}

.itembox02-bottomBtnWrap {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-right: 15px;
}

.optioncb-icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.itembox02-bottomBtnWrap2 {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 6px;
}

.itembox03-bottomBtnWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.itembox03-title0011 {
  font-size: 1.3rem;
  color: #000000;
  text-align: center;
  font-weight: 500;
}

.itembox03-title01 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 20px;
}

.itembox03-title02 {
  font-size: 0.8rem;
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
}

.itembox03-title022 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  min-height: 50px;
  font-size: 1rem;
  color: #ffffff;
  text-align: center;
  margin-top: 0px;
  font-weight: 600;
  padding: 7px 5px 5px 5px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: #ffffff;
}

.itembox01-bottomBtnWrap-btnText {
  font-size: 0.74rem;
  font-weight: bold;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color: #cfcfcf;
  padding: 7px 10px 5px 10px;
  border-radius: 4px;
}

.itembox01-bottomBtnWrap-btnText22 {
  font-size: 0.65rem;
  font-weight: 500;
  cursor: pointer;
  padding: 9px 12px 6px 12px;
  border-radius: 8px;
  background-color: #f5c242;
}

.itembox01-bottomBtnWrap-btnText33 {
  font-size: 0.74rem;
  font-weight: 500;
  cursor: pointer;
  padding: 7px 14px 6px 14px;
  border-radius: 25px;
  background-color: #f5c242;
  color: #ffffff;
}

.bgMain {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../img/bg_gym.jpg");
  /* background-size:100% 100%; */
}

.loginBox {
  display: flex;
  position: relative;
  width: calc(100% - 40px);
  max-width: 400px;
  height: 320px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-bottom: 100px; */
}

.container-login {
  display: flex;
  position: relative;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginLogo {
  font-size: 1.4em;
  font-weight: bold;
  color: #000000;
  margin-top: 15px;
}

.loginIpBox {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  width: 86%;
  padding: 10px 0 10px 0;
  margin-top: 25px;
  border-radius: 10px;
}

.loginIpYelloBox {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  background-color: #000000;
  width: 86%;
  padding: 10px 0 10px 0;
  margin-top: 25px;
  border-radius: 10px;
  border-color: #f5c242;
  border-width: 1px;
  border-style: solid;
}

.logoTitleLogo {
  width: 100px;
  height: 100px;
}

.logoTitleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loginIpTitle {
  width: 80px;
  padding-left: 25px;
  font-size: 0.9rem;
  color: #292929;
}

.loginIpTitleWh {
  width: 80px;
  padding-left: 15px;
  font-size: 0.9rem;
  color: #ffffff;
  font-family: "Pretendard";
  font-weight: 600;
}

.logoTitle {
  margin-left: 25px;
  font-size: 90px;
  color: #ffffff;
  font-family: "Pretendard";
  font-weight: 600;
  margin-top: 10px;
}

.tutorialLogoTitle {
  margin-left: 15px;
  font-size: 42px;
  color: #ffffff;
  font-family: "Pretendard";
  font-weight: 500;
  margin-top: 10px;
}

.loginIpId {
  border-width: 0px;
  width: 100%;
  height: 100%;
  font-size: medium;
  color: #000000;
}

.loginIpIdBk {
  border-width: 0px;
  width: 100%;
  height: 100%;
  font-size: medium;
  color: #ffffff;
  background-color: #000000;
  padding-left: 15px;
}

.loginIpWrap {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 10px 0;
}

.loginBtn {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #db8400;
  width: 86%;
  height: 46px;
  margin-top: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.loginBtnTxt {
  color: #ffffff;
  font-size: large;
  font-weight: bold;
}

/* .sideBox {
    width: 224px;
    left: -224px;
    transition: left .1s ease-in;
    position: fixed;
    border-style: solid;
    border-right-width: 1px;
    border-color: #e6e6e6;
    box-sizing: border-box;
    background-image: url('../img/bg_nav2.jpg');
} */

.sideBox {
  width: 224px;
  left: -224px;
  transition: left 0.1s ease-in;
  position: fixed;
}

.sideBox-open {
  left: 0px;
  transition: left 0.2s ease-in;
}

.sideSbBox {
  width: 224px;
  left: -224px;
  transition: left 0.1s ease-in;
  position: fixed;
}

.sideSbBox-open {
  left: 0px;
  transition: left 0.2s ease-in;
}

.scrollY-box {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  /* Firefox */
}

.scrollY-box::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera*/
}

.NavSide-img {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  color: #ffffff;
}

.NavSbSide-box {
  width: 100%;
  align-items: center;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  justify-content: center;
  /* color: #cfcfcf; */
}

.NavSide-box {
  width: 100%;
  align-items: center;
  display: flex;
  padding-left: 22px;
  height: 56px;
  border-width: 0px;
  border-bottom-width: 0.8px;
  border-color: #6b6b6b;
  border-style: solid;
  cursor: pointer;
  color: #cfcfcf;
}

.NavSide-box:hover {
  color: #c4793b;
}

.NavSide-logoTxt {
  font-size: 0.9rem;
  font-weight: normal;
}

.react-switch {
  vertical-align: middle;
  margin-right: 15px;
}

.titleBtn-Con {
  display: flex;
  height: 34px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background-color: #09348a;
  color: #000000;
  padding: 1px 28px 0px 28px;
  border-radius: 21px;
  box-shadow: 0 3px 12px 0 rgb(31 38 135 / 20%);
  margin-right: 12px;
  cursor: pointer;
  overflow: hidden;
}

.titleBtn-Con-del {
  display: flex;
  height: 34px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f7f8ff;
  border-width: 1px;
  border-style: solid;
  border-color: #d60a0a;
  color: #d60a0a;
  padding: 1px 28px 0px 28px;
  border-radius: 21px;
  box-shadow: 0 3px 12px 0 rgb(31 38 135 / 20%);
  margin-right: 12px;
  cursor: pointer;
  overflow: hidden;
}

.sbdayprogram-menuBtn-off {
  min-width: 120px;
  font-size: 1rem;
  color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 8px 10px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.sbdayprogram-menuBtn-on {
  min-width: 120px;
  font-size: 1rem;
  font-weight: bold;
  color: #ffae3c;
  border-style: solid;
  border-width: 1px;
  border-color: #ffae3c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 8px 10px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.modalBtnTxt {
  font-size: 1.2rem;
  color: #000000;
  font-family: "Pretendard";
  font-weight: 500;
  margin-top: 2.1px;
}

.adminGotoBtnTxt {
  font-size: 0.9rem;
  color: #ffffff;
  font-family: "Pretendard";
  font-weight: 600;
  margin-top: 2.1px;
}

.NavSbSide-logoTxt {
  font-size: 1rem;
  color: #ffffff;
  font-family: "Pretendard";
  font-weight: 600;
  margin-top: 5px;
  text-align: center;
}

.sbdayprogram-dayTiTleText {
  font-size: 1.4rem;
  color: #ffffff;
  font-family: "Pretendard";
  font-weight: 600;
}

.sbdayprogram-calBox-on {
  color: #000000;
  background-color: #ffbf00;
  border-color: #ffbf00;
  font-weight: bold;
}

.sbdayprogram-calBox-off {
  color: #ffffff;
  font-weight: normal;
  border-color: #ffffff;
}

.sbdayprogram-calBox {
  min-width: 60px;
  width: 60px;
  max-width: 60px;
  padding: 10px 13px;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1rem;
  cursor: pointer;
  font-family: "Pretendard";
  font-weight: 500;
}

.sbdayprogram-calBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 64px;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
}

.titleBtn-text {
  font-size: 0.8rem;
}

.modal-searchBox-Con {
  display: flex;
  width: 100%;
  height: 34px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f8ff;
  border-width: 1px;
  border-style: solid;
  border-color: #a1a1a1;
  padding-left: 7px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 21px;
  box-shadow: 0 3px 12px 0 rgb(31 38 135 / 20%);
}

.searchBox-selectbranch {
  display: flex;
  width: 100%;
  height: 34px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f8ff;
  border-width: 1px;
  border-style: solid;
  border-color: #a1a1a1;
  padding-left: 7px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 21px;
  box-shadow: 0 3px 12px 0 rgb(31 38 135 / 20%);
  margin-bottom: 30px;
}

.searchBox-pv {
  display: flex;
  width: 590px;
  margin-right: 40px;
  height: 34px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f8ff;
  border-width: 1px;
  border-style: solid;
  border-color: #a1a1a1;
  padding-left: 7px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 21px;
  box-shadow: 0 3px 12px 0 rgb(31 38 135 / 20%);
  margin-bottom: 30px;
}

.searchBox-Con {
  display: flex;
  height: 34px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f8ff;
  border-width: 1px;
  border-style: solid;
  border-color: #a1a1a1;
  padding-left: 7px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 21px;
  box-shadow: 0 3px 12px 0 rgb(31 38 135 / 20%);
}

.searchBox-IpWrap {
  display: flex;
  position: relative;
  flex: 1;
  font-size: 0.8rem;
  flex-direction: row;
  align-items: center;
  border-width: 0;
  background-color: #f7f8ff;
  padding-left: 13px;
  width: 120px;
}

.searchBox-ImgBtn {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

.solidSelect {
  border-radius: 0;
  /* 아이폰 사파리 보더 없애기 */
  -webkit-appearance: none;
  /* 화살표 없애기 for chrome*/
  -moz-appearance: none;
  /* 화살표 없애기 for firefox*/
  appearance: none;
  /* 화살표 없애기 공통*/
  border-width: 0px;
  background-color: #ffffff;
  -webkit-appearance: menulist-text;
}

.solidSelect::-ms-expand {
  display: none;
  /* 화살표 없애기 for IE10, 11*/
}

.adminGotoStrBtn {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-color: #09348a;
  border-style: solid;
  border-width: 2px;
  height: 46px;
  margin-top: 30px;
  border-radius: 23px;
  cursor: pointer;
  padding: 5px 20px;
}

.modalSaveStrBtn {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-color: #09348a;
  border-style: solid;
  border-width: 2px;
  width: 100%;
  height: 46px;
  margin-top: 30px;
  border-radius: 23px;
  cursor: pointer;
}

.packagesItem-tab-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.packagesItem-tab-btn-on {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 4px;
  border-color: #09348a;
  color: #09348a;
  cursor: pointer;
}

.packagesItem-tab-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 2px;
  border-color: #686868;
  color: #000000;
  cursor: pointer;
}

.modalSaveBtn {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background-color: #09348a;
  border-color: #09348a;
  border-style: solid;
  border-width: 2px;
  width: 100%;
  height: 46px;
  margin-top: 30px;
  border-radius: 23px;
  cursor: pointer;
}

.moreBtn {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background-color: #09348a;
  border-color: #09348a;
  border-style: solid;
  border-width: 2px;
  width: 94%;
  margin-left: 3%;
  height: 46px;
  margin-top: 30px;
  border-radius: 23px;
  margin-bottom: 20px;
  cursor: pointer;
}

.noticeTa {
  border-width: 0px;
  width: 100%;
  font-size: small;
  color: #000000;
  margin-top: 5px;
  padding-top: 5px;
  resize: none;
}

.modalTimeExTxt {
  border-width: 0px;
  width: 100%;
  height: 30px;
  font-size: 1.3rem;
  color: #000000;
  background-color: #ebebeb;
  text-align: center;
  font-weight: 600;
}

.myTimeExTxt {
  border-width: 0px;
  width: 100%;
  height: 36px;
  font-size: 1.3rem;
  color: #000000;
  background-color: #ffffff;
  font-weight: 600;
  /* text-align: center; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-left: 5px;
}

.myTimeExTxtAll {
  font-size: 2.3rem;
  color: #000000;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}

.modalTimeExIp {
  border-width: 0px;
  width: 100%;
  height: 30px;
  font-size: 1.3rem;
  color: #000000;
  padding-left: 10px;
  background-color: #ebebeb;
  text-align: center;
  font-weight: 600;
}

.informationContents {
  border-width: 0px;
  width: 100%;
  height: 80px;
  font-size: small;
  color: #000000;
  padding-left: 10px;
  text-align: start;
  resize: none;
  padding-top: 10px;
}

.replyModalIp {
  border-width: 0px;
  width: 100%;
  height: 80px;
  margin-left: 10px;
  font-size: small;
  color: #000000;
  padding-left: 10px;
  text-align: start;
  resize: none;
  padding-top: 10px;
}

.modalIp {
  border-width: 0px;
  width: 100%;
  height: 28px;
  font-size: small;
  color: #000000;
  padding-left: 10px;
}

.packagesItem-tab-txt {
  font-family: "Pretendard";
  font-weight: 600;
  font-size: 1.2em;
  padding-left: 10px;
}

.modalIpAdmin {
  border-width: 0px;
  width: 100%;
  height: 34px;
  font-family: "Pretendard";
  font-weight: 600;
  font-size: 1.2em;
  color: #000000;
  padding-left: 10px;
  background-color: #ebebeb;
}

.modalSp {
  border-width: 0px;
  width: 100%;
  font-size: small;
  color: #000000;
  margin-top: 5px;
  white-space: pre-wrap;
}

.fileBtn {
  font-family: "Pretendard";
  font-weight: 600;
  font-size: 0.92em;
  background-color: #d1d1d1;
  color: #000000;
  border-radius: 5px;
  border-style: solid;
  border-width: 0px;
  padding: 5px 12px 4px 12px;
}

.fileBtnSub {
  /* font-family: 'Pretendard'; */
  /* font-weight: 400; */
  font-size: 1em;
  color: #565656;
  padding-left: 10px;
  max-width: 260px;
  display: flex;
  flex: 1;
  overflow: hidden;
}

.menuBtnWrap {
  display: flex;
  width: 80%;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-color: #c7c7c7;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 1px;
  cursor: pointer;
}

.modalExTimeWrap {
  display: flex;
  width: 96%;
  flex-direction: row;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: #000000;
  border-style: solid;
  padding-bottom: 24px;
}

.myExTimeInnerWrap {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.modalExTimeInnerWrap {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.menuBtnTxt {
  font-size: medium;
  color: #000000;
  font-weight: normal;
}

.modalIpWrap {
  width: 100%;
  padding-bottom: 5px;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #e0e0e0;
  margin-bottom: 15px;
}

.modalIpWrapCenter {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-items: center;
  border-width: 0 0 0 0;
}

.modalIpWrapColumn {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  border-width: 0 0 0 0;
}

.modalIpTitle {
  min-width: 110px;
  font-size: 0.8em;
  color: #000000;
  font-family: "Pretendard";
  font-weight: 600;
}

.modalIpTitleExTime {
  min-width: 90px;
  min-height: 32px;
  /* text-align: center; */
  font-size: 1.2em;
  color: #000000;
  font-family: "Pretendard";
  font-weight: 600;
  margin-bottom: 10px;
  border-bottom-style: solid;
  border-bottom-width: 1.2px;
  border-bottom-color: #f7c456;
  padding-left: 5px;
}

.modalIpTitleAdmin {
  font-size: 1.2em;
  color: #000000;
  font-family: "Pretendard";
  font-weight: 600;
}

.modalIpTitle2 {
  font-size: small;
  color: #000000;
  font-family: "Pretendard";
  font-weight: 600;
}

.modalProgramDndWrap {
  width: 31%;
  margin-right: 1%;
  margin-left: 1%;
  display: flex;
  position: relative;
  flex-direction: column;
  border-width: 1px;
  border-style: solid;
  border-color: #dbdbdb;
  padding: 5px 5px;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #ffffff;
}

.modalProgramDndWrap-row {
  width: 104px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modalProgramDndIndex {
  font-size: 0.95em;
  color: #000000;
  font-weight: bold;
  text-align: center;
  margin-left: 5px;
}

.modalProgramDndKey {
  font-size: 0.95em;
  color: #000000;
  font-weight: bold;
  text-align: center;
  margin-left: 5px;
}

.modalProgramDndTxtDel {
  font-size: 0.8em;
  color: #df0000;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.modalProgramDndIp {
  border-width: 0;
  width: calc(100% - 11px);
  padding-left: 6px;
  padding-right: 5px;
  font-size: 0.85em;
  color: #3e3e3e;
  margin-top: 4px;
  margin-bottom: 3px;
}

.modalProgramDndImg {
  width: calc(100% - 10px);
  height: auto;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 8px;
  object-fit: cover;
}

.filterBtn {
  display: flex;
  height: 42px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f8ff;
  border-width: 1px;
  border-style: solid;
  border-color: #8a8aff;
  padding-left: 7px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  margin-right: 6px;
  margin-left: 6px;
  border-radius: 21px;
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
}

.titleBtn-calendarIp {
  border-width: 0px;
  width: 100%;
  height: 100%;
  font-size: small;
  color: #000000;
  padding-left: 10px;
  background-color: #f7f8ff;
}

.Sales-price {
  font-size: 1.2rem;
  margin-left: 6px;
  margin-right: 35px;
  color: #5e5e5e;
  margin-bottom: 20px;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #5e5e5e;
  border-style: solid;
  padding-bottom: 12px;
  cursor: pointer;
}

.myItemBox {
  min-height: 521px;
  border: 2.5px solid #f5c242;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* height: calc(100% - 30px); */
}

.myItemBoxNone {
  min-height: 300px;
  border: 2.5px solid #f5c242;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.programAddContents {
  width: 100%;
  height: 28px;
  font-size: 1.2em;
  color: #000000;
  padding-left: 10px;
  border-width: 0px;
}

.programAddTitle {
  padding-left: 5px;
  min-width: 75px;
  font-size: 1.2em;
  color: #000000;
  font-weight: bold;
}

.videoDndWrap {
  min-width: 200px;
  display: flex;
  position: relative;
  flex-direction: column;
  border-width: 1px;
  border-style: solid;
  border-color: #dbdbdb;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 15px;
}

.videoDndWrap-in {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-right: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 15px;
  overflow: hidden;
}

.videoDndWrap-select {
  background-color: #aaaaaa;
}

.videoDndWrap-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
  padding: 0px 10px;
}

.videoDndWrap-img {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  min-height: 140px;
  max-height: 140px;
}

.videoDndWrap-imgTitle {
  border-width: 0px;
  width: 100%;
  font-size: 0.9em;
  color: #3e3e3e;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.videoDndWrap-del {
  font-size: 1.2em;
  color: #df0000;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  padding: 3px;
}

/* Sales.tx TABLE *************************************************************************** */
.Pay-table {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 3fr; */
  grid-template-columns: repeat(4, auto);
  /* max-width: 100vw; */
  padding-left: 17px;
  padding-right: 14px;
  table-layout: auto;
  width: 100%;
}

.Sales-table {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 3fr; */
  grid-template-columns: repeat(5, auto);
  /* max-width: 100vw; */
  padding-left: 17px;
  padding-right: 14px;
  table-layout: auto;
  width: 100%;
}

.Sales-table-cd {
  min-width: 120px;
  /* max-width: 300px; */
  color: #000000;
  background: #ffffff;
  padding: 16px 15px;
  border-style: solid;
  border-width: 0px;
  border-bottom-color: #e6e6e6;
  border-bottom-width: 0.5px;
}

.Sales-table-header {
  font-weight: bold;
  color: #000000;
  background: #ebebeb;
  padding: 12px 15px;
}

/* Sales.tx TABLE END *************************************************************************** */

.container-root {
  width: 100%;
  /* width: calc(100% - 40px); */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
}

.loader-containner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 5px solid #f3f3f3;
  /* Light grey */
  border-top: 5px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* 햄버거 메뉴 css : https://codepen.io/designcouch/pen/Atyop */

.nav-icon1,
#nav-icon2,
#nav-icon3,
#nav-icon4 {
  width: 20px;
  height: 18px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 2.5px;
  width: 100%;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.nav-icon1 span:nth-child(1) {
  top: 0px;
}

.nav-icon1 span:nth-child(2) {
  top: 7px;
}

.nav-icon1 span:nth-child(3) {
  top: 14px;
}

.nav-icon1open span:nth-child(1) {
  top: 7px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.nav-icon1open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.nav-icon1open span:nth-child(3) {
  top: 7px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* ******************************************************************************************* */
